<template>
  <TransitionGroup
    class="toast-queue-wrapper"
    name="toast-slide"
    tag="div"
  >
    <ZToast
      v-for="toast in toasts"
      :key="toast.id"
      :message="toast.message"
      :variant="toast.variant"
    />
  </TransitionGroup>
</template>

<script setup lang="ts">
const { toasts } = useToast()
</script>

<style lang="scss" scoped>
.toast-queue-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  bottom: 5rem;
  z-index: $headerZindex + 10;
  left: 0;
}
</style>
