/**
 * This middleware checks if Maintenance Mode is enbaled and if so, returns a
 * 503.
 */
export default defineNuxtRouteMiddleware(() => {
  const nuxtApp = useNuxtApp()
  const event = useRequestEvent()

  if (nuxtApp.$experiment.isEnabled('maintenanceMode') && event) {
    setResponseStatus(event, 503)
  }
})
