import { decodeJSON, encodeJSON } from '~/lib/base64'
import { getConfigCatUserObject, trackExperiment, trackStartup } from '~/lib/experiments/utils'
import { COOKIES } from '~/constants'
import type { Experiments } from '~/types'

export default defineNuxtPlugin((nuxtApp) => {
  const experiments = useExperiments()
  const runtimeConfig = useRuntimeConfig()
  const { isWebView } = usePlatform()
  const sessionId = useCookie(COOKIES.session)
  const { geolocation } = useGeolocation()
  const device = useDevice()
  const { user } = useAuthentication()

  if (import.meta.server) {
    experiments.value = nuxtApp.$evaluatedExperiments as Experiments
  }

  function get(experimentKey: string) {
    return experiments.value.evaluated[experimentKey]
  }

  function getVariant(experimentKey: string) {
    return get(experimentKey)?.variant
  }

  function getAll() {
    return experiments.value.evaluated
  }

  function getOverrides() {
    return experiments.value.overridden
  }

  function getOverride(experimentKey: string, defaultValue: string | boolean | number) {
    return get(experimentKey)?.overrider ?? defaultValue
  }

  function isEnabled(experimentKey: string) {
    if (!experimentKey) return false

    const experiment = get(experimentKey)
    if (!experiment) return false

    return experiment.variant !== experiment.defaultVariant
  }

  function trackView({ experimentKey, startup = false }: { experimentKey: string, startup?: boolean }) {
    const experiment = get(experimentKey)
    trackExperiment(experiment, { startup, isWebview: isWebView.value })
  }

  function trackStartupExperiments() {
    trackStartup(getAll(), isWebView.value)
  }

  async function updateOverride(experimentKey: string, value: string | boolean | number) {
    const cookie = getOverrideCookie()

    if (value === null) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete cookie[experimentKey]
    }
    else {
      cookie[experimentKey] = value
    }

    setOverrideCookie(cookie)
  }

  function getOverrideCookie() {
    const cookie = useCookie(COOKIES.experimentOverrides)
    return decodeJSON(cookie.value)
  }

  function setOverrideCookie(overrides: Record<string, unknown>) {
    const cookie = useCookie(COOKIES.experimentOverrides, {
      domain: runtimeConfig.public.cookieDomain,
      path: '/',
      maxAge: overrides['*permanent'] ? nuxtApp.$config.public.sessionCookieTTL : undefined,
    })

    cookie.value = encodeJSON(overrides)

    // After an override cookie is set, we refresh the experiments to make sure
    // they're up to date.
    refreshExperiments()
  }

  function getFlag(flag: string) {
    const cookie = getOverrideCookie()
    return cookie[`*${flag}`]
  }

  function setFlag(flag: string, value: string | boolean | number) {
    const cookie = getOverrideCookie()
    cookie[`*${flag}`] = value
    setOverrideCookie(cookie)
  }

  function login() {
    setFlag('authenticated', true)
  }

  function logout() {
    setFlag('authenticated', false)
  }

  function isAuthenticated() {
    return getFlag('authenticated')
  }

  function setDefaultAll(value: string | boolean | number) {
    setFlag('defaultAll', value)
  }

  function getDefaultAll() {
    return getFlag('defaultAll')
  }

  function setPermanent(value: string | boolean | number) {
    setFlag('permanent', value)
  }

  function getPermanent() {
    return getFlag('permanent')
  }

  async function refreshExperiments() {
    if (import.meta.dev) {
      console.log('Refreshing experiments')
    }

    const utils = {
      user: {
        Id: user.value?.Id,
        Email: user.value?.Email,
        Language: user.value?.Language,
        IsOwner: user.value?.IsOwner,
      },
      session: sessionId.value,
      ccUser: getConfigCatUserObject({
        user: user.value,
        config: runtimeConfig,
        $device: device,
        sessionId: sessionId.value,
        $geolocation: geolocation.value,
      }),
    }

    const response = await $fetch(`${runtimeConfig.public.baseURL}/api/experiments`, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      body: utils,
    })

    experiments.value = response as Experiments
  }

  return {
    provide: {
      experiment: {
        trackView,
        trackStartupExperiments,
        getVariant,
        isEnabled,
        get,
        getAll,
        refreshExperiments,
        updateOverride,
        getOverrides,
        getOverride,
        login,
        logout,
        isAuthenticated,
        setDefaultAll,
        getDefaultAll,
        setPermanent,
        getPermanent,
      },
    },
  }
})
