import type {
  openSession,
  changeUser,
  destroy,
  isPushSupported,
  isPushPermissionGranted,
  isPushBlocked,
  requestPushPermission,
  unregisterPush,
  logCustomEvent,
  wipeData,
} from '@braze/web-sdk'
import createBraze from '~/lib/createBraze'

let braze: {
  openSession: typeof openSession
  changeUser: typeof changeUser
  destroy: typeof destroy
  isPushSupported: typeof isPushSupported
  isPushPermissionGranted: typeof isPushPermissionGranted
  isPushBlocked: typeof isPushBlocked
  requestPushPermission: typeof requestPushPermission
  unregisterPush: typeof unregisterPush
  logCustomEvent: typeof logCustomEvent
  wipeData: typeof wipeData
}

export default async function () {
  if (!braze && import.meta.client) {
    const runtimeConfig = useRuntimeConfig()
    const $brazeConfig = runtimeConfig.public.braze

    braze = await createBraze($brazeConfig.apiKey, $brazeConfig.sdkUrl)
  }

  const canRequestPushMessagesPermission = () => {
    return braze.isPushSupported() && !braze.isPushPermissionGranted() && !braze.isPushBlocked()
  }

  const isWebPushSupported = () => {
    return braze.isPushSupported()
  }

  const isWebPushBlocked = () => {
    return braze.isPushBlocked()
  }

  const isWebPushAllowed = () => {
    return braze.isPushPermissionGranted()
  }

  const requestPushMessagesPermission = (success: () => void, denied: (arg0: boolean) => void) => {
    braze.requestPushPermission(
      () => success(),
      (isTemporary) => denied(isTemporary),
    )
  }

  const reset = () => {
    braze.wipeData()
  }

  const removePushMessagePermission = () => {
    braze.unregisterPush()
  }

  return {
    openSession: braze?.openSession,
    setUser: braze?.changeUser,
    destroy: braze?.destroy,
    logCustomEvent: braze?.logCustomEvent,
    canRequestPushMessagesPermission,
    isWebPushSupported,
    isWebPushBlocked,
    isWebPushAllowed,
    requestPushMessagesPermission,
    removePushMessagePermission,
    reset,
  }
}
