<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  cols?: number | string
  xs?: number | string
  sm?: number | string
  md?: number | string
  lg?: number | string
  xl?: number | string
  xxl?: number | string
  xxxl?: number | string
  start?: number | string
  startLg?: number | string
  startXl?: number | string
}>(), {
  cols: 12,
})

const isValidSpan = (span?: string | number) => {
  if (!span) {
    return false
  }

  const newSpan = typeof span === 'number' ? span : parseInt(span)

  return newSpan >= 1 && newSpan <= 12
}

const spanCols = computed(() => {
  if (isValidSpan(props.cols)) return `g-col-${props.cols}`
  return undefined
})

const spanXS = computed(() => {
  if (isValidSpan(props.xs)) return `g-col-xs-${props.xs}`
  return undefined
})

const spanSM = computed(() => {
  if (isValidSpan(props.sm)) return `g-col-sm-${props.sm}`
  return undefined
})

const spanMD = computed(() => {
  if (isValidSpan(props.md)) return `g-col-md-${props.md}`
  return undefined
})

const spanLG = computed(() => {
  if (isValidSpan(props.lg)) return `g-col-lg-${props.lg}`
  return undefined
})

const spanXL = computed(() => {
  if (isValidSpan(props.xl)) return `g-col-xl-${props.xl}`
  return undefined
})

const spanXXL = computed(() => {
  if (isValidSpan(props.xl)) return `g-col-xxl-${props.xxl}`
  return undefined
})

const spanXXXL = computed(() => {
  if (isValidSpan(props.xl)) return `g-col-xxxl-${props.xxxl}`
  return undefined
})

const startClass = computed(() => {
  if (isValidSpan(props.start)) return `g-col-start-${props.start}`
  return undefined
})

const startLgClass = computed(() => {
  if (isValidSpan(props.startLg)) return `g-col-start-lg-${props.startLg}`
  return undefined
})

const startXlClass = computed(() => {
  if (isValidSpan(props.startXl)) return `g-col-start-xl-${props.startXl}`
  return undefined
})

const classes = computed(() => [
  'zgrid-item',
  spanCols.value,
  spanXS.value,
  spanSM.value,
  spanMD.value,
  spanLG.value,
  spanXL.value,
  spanXXL.value,
  spanXXXL.value,
  startClass.value,
  startLgClass.value,
  startXlClass.value,
])
</script>
