import * as trackingMethods from '~/lib/tracking/events'

/**
 * This composable exposes all the tracking methods from the tracking library.
 */
export default function () {
  return {
    ...trackingMethods,
  }
}
