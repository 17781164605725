import type { LayoutKey } from '#build/types/layouts'
import { COOKIES } from '~/constants'

/**
 * This composable returns the layout type based on the platform cookie.
 */
export default function (layout: LayoutKey = 'default') {
  const platformCookie = useCookie(COOKIES.platform)

  if (platformCookie) {
    if (platformCookie.value === 'android' || platformCookie.value === 'ios-v1') {
      return 'blank'

      /**
       * This was copied over from auth branch, however for error page work I needed layout to be 'webview'.
       * To review when merging Auth.
       *    return 'minimal'
       */
    }
  }

  return layout
}
