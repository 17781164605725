import { COOKIES } from '~/constants'

/**
 * This composable checks if the user is using a webview.
 *
 * @todo might be better to move this to a cookie-related composable?
 */
export default function useWebView() {
  const platformCookie = useCookie(COOKIES.platform)

  const isWebView = computed(() => {
    return platformCookie.value === 'android' || platformCookie.value === 'ios-v1'
  })

  return {
    isWebView,
  }
}
