<!-- eslint-disable vue/no-v-html -->
<template>
  <ZContainer>
    <ZGrid v-if="!isDev">
      <ZGridItem md="6">
        <LogoRvezy class="logo" />

        <template v-if="error.statusCode === 404">
          <h1>{{ t('pages.error.title.fourOhFour') }}</h1>
          <p>{{ t('pages.error.body.fourOhFour') }}</p>
        </template>

        <template v-else>
          <h1>{{ t('pages.error.title.others') }}</h1>
          <p>{{ t('pages.error.body.others') }}</p>
        </template>

        <ul>
          <li>
            <NuxtLink :to="localePath('index')">
              {{ $t('pageLink.home') }}
            </NuxtLink>
          </li>
          <li>
            <NuxtLink :to="localePath('rv-search')">
              {{ $t('pageLink.searchRVs') }}
            </NuxtLink>
          </li>
          <li>
            <NuxtLink :to="localePath('how-it-works')">
              {{ $t('pageLink.howRVezyWorks') }}
            </NuxtLink>
          </li>
          <li>
            <NuxtLink :to="linkListRv">
              {{ $t('pageLink.listAnRV') }}
            </NuxtLink>
          </li>
          <li>
            <NuxtLink :to="localePath('about')">
              {{ $t('pageLink.aboutRVezy') }}
            </NuxtLink>
          </li>
          <li>
            <NuxtLink :to="localePath('blog')">
              {{ $t('pageLink.blog') }}
            </NuxtLink>
          </li>
        </ul>
      </ZGridItem>

      <ZGridItem md="6">
        <ZImage
          fluid
          :src="`${$config.public.staticAssetsURL}/web-app/experience-blog/assets/images/lady-looking-into-sunset-on-deck_800x600.jpg`"
          :alt="t('pages.error.heroImageAlt')"
          :title="t('pages.error.heroImageAlt')"
        />
      </ZGridItem>
    </ZGrid>
    <div v-else>
      <h1>Error {{ error.statusCode }}</h1>
      <p>{{ error.message }}</p>
      <div
        class="dev-error"
        v-html="error.stack"
      />
      <pre class="dev-error">{{ error }}</pre>
    </div>
  </ZContainer>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

const localePath = useLocalePath()
const { linkListRv } = useOwnerCommon()
const isDev = computed(() => import.meta.dev)

const { t } = useI18n({
  useScope: 'local',
})

defineProps<{
  error: NuxtError
}>()
</script>

<style lang="scss" scoped>
.zcontainer {
  margin-top: 15vh;
  margin-bottom: 15vh;
}

.logo {
  width: 40%;
}

h1 {
  margin-top: 1.25rem;
}

.dev-error {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  background: #f5f5f5;
  font-size: 14px;
  min-height: 100%;
}
</style>

<i18n src="~/locales/common/pages/error.json" lang="json" />
