import { type IRouteManager, type IRoute, IRouteReleaseGroupEnum } from '~/types/interfaces/IRouteManager'
import { pathMatcher } from '~/utils/path-matcher'

/**
 * disabled: no redirect
 * off: 0% (all Nuxt 2)
 * alpha: 5%
 * beta: 45%
 * control: 100% (all Nuxt 3)
 */

export default function () {
  const { $experiment } = useNuxtApp()
  const { routeManager, nuxt2BaseUrl, nuxt2Redirect } = useRuntimeConfig().public
  const routeManagerObj = <IRouteManager>routeManager

  const isDisabledUser = $experiment.getVariant('rvz16504NewWebclientInNuxt3') === 'disabled'
  const isOffUser = $experiment.getVariant('rvz16504NewWebclientInNuxt3') === 'off'
  const isAlphaUser = $experiment.getVariant('rvz16504NewWebclientInNuxt3') === 'alpha'
  const isBetaUser = $experiment.getVariant('rvz16504NewWebclientInNuxt3') === 'beta'

  const hasMatchingRoutes = (path: string) =>
    routeManagerObj?.Routes.some((x: IRoute) => {
      if (isOffUser) {
        return false
      }

      const pathMatches = pathMatcher(x.Pattern, path)

      // If route release group is alpha, only alpha users can access it
      if (x.ReleaseGroup === IRouteReleaseGroupEnum.Alpha) {
        return pathMatches && isAlphaUser
      }

      // If route release group is beta, only alpha and beta users can access it
      if (x.ReleaseGroup === IRouteReleaseGroupEnum.Beta) {
        return pathMatches && (isAlphaUser || isBetaUser)
      }

      // If route release group is control, any users can access it
      return pathMatches
    })

  /**
   * Resolves the redirect URL for a given route.
   * @param path - The normalized route location or type RouteLocationNormalized.
   * @returns True if the path should be redirected, false otherwise.
   */
  const shouldRedirectRouteUrl = (path: string): boolean => {
    return routeManagerObj?.RedirectRoutes.some((x) => pathMatcher(x, path))
  }

  /**
   * Determines if a route should be redirected to Nuxt 2.
   * @param path - The path to check.
   * @returns True if the path should be redirected to Nuxt 2, false otherwise.
   */
  const shouldRedirectToNuxt2App = (path: string) =>
    !['/experiments', '/callback-oauth'].includes(path) && !isDisabledUser && nuxt2Redirect && !hasMatchingRoutes(path)

  return {
    shouldRedirectRouteUrl,
    shouldRedirectToNuxt2App,
    nuxt2BaseUrl,
  }
}
