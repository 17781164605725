<template>
  <div :class="containerClass">
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  fluid?: boolean
}>(), {
  fluid: false,
})

const containerClass = computed(() => {
  return props.fluid ? 'zcontainer-fluid' : 'zcontainer'
})
</script>
