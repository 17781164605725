<template>
  <div>
    <NuxtLayout :name="layout">
      <LazyAppErrorsRvUnpublished
        v-if="isRvUnpublishedError"
        :error="error"
      />
      <AppErrorsDefault
        v-else
        :error="error"
      />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

const isRvUnpublishedError = computed(() => props.error.message === 'RV_UNPUBLISHED')
const layout = computed(() => useLayout())
const title = computed(() => {
  return isRvUnpublishedError.value
    ? t('pages.error.title.rvUnpublished')
    : props.error.statusCode === 404
      ? t('pages.error.title.fourOhFour')
      : t('pages.error.title.others')
})

const { t } = useI18n({
  useScope: 'local',
})

const props = defineProps<{
  error: NuxtError
}>()

useHead({
  title,
})
</script>

<i18n src="~/locales/common/pages/error.json" lang="json" />
