import { default as aboutRtT40TxSnBMeta } from "/home/vsts/work/1/s/src/pages/about.vue?macro=true";
import { default as indexYptU35G8UcMeta } from "/home/vsts/work/1/s/src/pages/affiliates/index.vue?macro=true";
import { default as app_45downloadIKyRSC9eylMeta } from "/home/vsts/work/1/s/src/pages/app-download.vue?macro=true";
import { default as _91slug_931rvzxnsy8lMeta } from "/home/vsts/work/1/s/src/pages/author/[slug].vue?macro=true";
import { default as _91slug_932ee6NKaFpBMeta } from "/home/vsts/work/1/s/src/pages/blog/[slug].vue?macro=true";
import { default as indexdNzrMeXp3pMeta } from "/home/vsts/work/1/s/src/pages/blog/index.vue?macro=true";
import { default as callback_45oauth7DmN7JzlW7Meta } from "/home/vsts/work/1/s/src/pages/callback-oauth.vue?macro=true";
import { default as careers_45data_45analystcwjeewoezfMeta } from "/home/vsts/work/1/s/src/pages/careers-data-analyst.vue?macro=true";
import { default as careers_45owner_45successuqqiYY4hoHMeta } from "/home/vsts/work/1/s/src/pages/careers-owner-success.vue?macro=true";
import { default as careers_45trust_45and_45safetyOUNdpB5qVvMeta } from "/home/vsts/work/1/s/src/pages/careers-trust-and-safety.vue?macro=true";
import { default as careers7IEeCe4chaMeta } from "/home/vsts/work/1/s/src/pages/careers.vue?macro=true";
import { default as _91slug_93wdFiE6DNuDMeta } from "/home/vsts/work/1/s/src/pages/category/[slug].vue?macro=true";
import { default as agreementjOjz6B34xJMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/agreement.vue?macro=true";
import { default as accept5fryq3kaG9Meta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/change-request/accept.vue?macro=true";
import { default as declineoTkxVfv8tRMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/change-request/decline.vue?macro=true";
import { default as reviewgEtSaxYklwMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/change-request/review.vue?macro=true";
import { default as change_45requestdcEuYnLiERMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/change-request.vue?macro=true";
import { default as acceptsUhRT5p9LMMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/accept.vue?macro=true";
import { default as accepted3x3XgQwdPeMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/accepted.vue?macro=true";
import { default as cancelTWT8Hwb6NvMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/cancel.vue?macro=true";
import { default as completenPI97ej1MQMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/complete.vue?macro=true";
import { default as custom_45quoteOe32uMCY8wMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/custom-quote.vue?macro=true";
import { default as declineZMrR2LAud6Meta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/decline.vue?macro=true";
import { default as edit1Cx97QgIoAMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/edit.vue?macro=true";
import { default as income_45protectionvJkKNBxoPaMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/income-protection.vue?macro=true";
import { default as insurancel99WMNoolTMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/insurance.vue?macro=true";
import { default as indexW51V7uDj4FMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index.vue?macro=true";
import { default as insurance_45efnolKfxjuZY1FeMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/insurance-efnol.vue?macro=true";
import { default as invoicePQdlgOsK3cMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/invoice.vue?macro=true";
import { default as paymentYVa7X9C7Y9Meta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/payment.vue?macro=true";
import { default as retry_45paymentoKJz01yw43Meta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/retry-payment.vue?macro=true";
import { default as reviewr7FnNHb40xMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/review.vue?macro=true";
import { default as disputeObaIFIaA9uMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/dispute.vue?macro=true";
import { default as index8oeeBBNCElMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/index.vue?macro=true";
import { default as requestv6LQZbNU3RMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/request.vue?macro=true";
import { default as reviewAeOFz24S1OMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/review.vue?macro=true";
import { default as sendNTSkKp41rvMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/send.vue?macro=true";
import { default as sentd20xn3ihmgMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/sent.vue?macro=true";
import { default as successVHcujwuf4mMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/success.vue?macro=true";
import { default as send_45request_45moneyTcZgssIVloMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money.vue?macro=true";
import { default as completeszXsf4RY3CMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/services/update/complete.vue?macro=true";
import { default as index9sEDdEBAebMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/services/update/index.vue?macro=true";
import { default as reviewMEHVczqhIvMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/services/update/review.vue?macro=true";
import { default as servicesvRnGmJrdo1Meta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/services.vue?macro=true";
import { default as update_45payment0kqG6dZgRmMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/update-payment.vue?macro=true";
import { default as completedTYWl1F9DkMMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/index/completed.vue?macro=true";
import { default as confirmedJO3y3CcbbAMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/index/confirmed.vue?macro=true";
import { default as ezymatchHaTMrNS2t7Meta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/index/ezymatch.vue?macro=true";
import { default as indexvYy9nNZpVwMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/index/index.vue?macro=true";
import { default as pendingCl6AodehY1Meta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/index/pending.vue?macro=true";
import { default as indexG3sLdXI9SgMeta } from "/home/vsts/work/1/s/src/pages/dashboard/bookings/index.vue?macro=true";
import { default as calendar3NSUoo2QmyMeta } from "/home/vsts/work/1/s/src/pages/dashboard/calendar.vue?macro=true";
import { default as favouritesqCXDR8Q0SyMeta } from "/home/vsts/work/1/s/src/pages/dashboard/favourites.vue?macro=true";
import { default as indexmZiebELbRsMeta } from "/home/vsts/work/1/s/src/pages/dashboard/index.vue?macro=true";
import { default as insightsG0VLzXxFtEMeta } from "/home/vsts/work/1/s/src/pages/dashboard/insights.vue?macro=true";
import { default as _91id_930eME34Nh2yMeta } from "/home/vsts/work/1/s/src/pages/dashboard/messages/[id].vue?macro=true";
import { default as archiveswdqDAypagoMeta } from "/home/vsts/work/1/s/src/pages/dashboard/messages/archives.vue?macro=true";
import { default as enquiriesVUEpCi7Ef4Meta } from "/home/vsts/work/1/s/src/pages/dashboard/messages/enquiries.vue?macro=true";
import { default as indexIXjuNdfJMvMeta } from "/home/vsts/work/1/s/src/pages/dashboard/messages/index.vue?macro=true";
import { default as requestoqXbPiv2o3Meta } from "/home/vsts/work/1/s/src/pages/dashboard/messages/request.vue?macro=true";
import { default as messagesDdxmNbYtDRMeta } from "/home/vsts/work/1/s/src/pages/dashboard/messages.vue?macro=true";
import { default as _91empty_93rbEcBKJuNvMeta } from "/home/vsts/work/1/s/src/pages/dashboard/profile/[empty].vue?macro=true";
import { default as indexF8jYGm0HNsMeta } from "/home/vsts/work/1/s/src/pages/dashboard/profile/drivers/index.vue?macro=true";
import { default as new1PqSD6RNYCMeta } from "/home/vsts/work/1/s/src/pages/dashboard/profile/drivers/new.vue?macro=true";
import { default as driversEf7sZAKNHHMeta } from "/home/vsts/work/1/s/src/pages/dashboard/profile/drivers.vue?macro=true";
import { default as featuresqOdysRyj1DMeta } from "/home/vsts/work/1/s/src/pages/dashboard/profile/features.vue?macro=true";
import { default as indexYo5RwYKMQJMeta } from "/home/vsts/work/1/s/src/pages/dashboard/profile/index.vue?macro=true";
import { default as message_45templates0opkZaLudZMeta } from "/home/vsts/work/1/s/src/pages/dashboard/profile/message-templates.vue?macro=true";
import { default as notificationswPePRgDYgMMeta } from "/home/vsts/work/1/s/src/pages/dashboard/profile/notifications.vue?macro=true";
import { default as paymentXSVIvq1GdyMeta } from "/home/vsts/work/1/s/src/pages/dashboard/profile/payment.vue?macro=true";
import { default as payoutIyVGyUCpMmMeta } from "/home/vsts/work/1/s/src/pages/dashboard/profile/payout.vue?macro=true";
import { default as profiled9T59OM8jUMeta } from "/home/vsts/work/1/s/src/pages/dashboard/profile.vue?macro=true";
import { default as addonscBAHPNtWgBMeta } from "/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/addons.vue?macro=true";
import { default as calendar2C7hlTeQyNMeta } from "/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/calendar.vue?macro=true";
import { default as detailsFsDeGR9hAfMeta } from "/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/details.vue?macro=true";
import { default as earn_45moreKmqHYRhO5eMeta } from "/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/earn-more.vue?macro=true";
import { default as indexjxtTVCxcKZMeta } from "/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/index.vue?macro=true";
import { default as insuranceXvaz14cTLrMeta } from "/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/insurance.vue?macro=true";
import { default as photosrLx3JZerEEMeta } from "/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/photos.vue?macro=true";
import { default as preferencesPQQVMc7PcLMeta } from "/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/preferences.vue?macro=true";
import { default as pricingRhOp92NXE3Meta } from "/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/pricing.vue?macro=true";
import { default as _91rv_45Id_938ZCQkiCwjrMeta } from "/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id].vue?macro=true";
import { default as deletedJLUmchGaLlMeta } from "/home/vsts/work/1/s/src/pages/dashboard/rvs/deleted.vue?macro=true";
import { default as _91field_93gUfwAD61CBMeta } from "/home/vsts/work/1/s/src/pages/dashboard/rvs/new/[step]/[field].vue?macro=true";
import { default as indexVutCzku2pYMeta } from "/home/vsts/work/1/s/src/pages/dashboard/rvs/new/[step]/index.vue?macro=true";
import { default as newwezFT46JF7Meta } from "/home/vsts/work/1/s/src/pages/dashboard/rvs/new.vue?macro=true";
import { default as _91id_93s1seKOtO1IMeta } from "/home/vsts/work/1/s/src/pages/dashboard/rvs/published/[id].vue?macro=true";
import { default as hostBBzKiD6B82Meta } from "/home/vsts/work/1/s/src/pages/dashboard/survey/host.vue?macro=true";
import { default as completedEgt78t9CnGMeta } from "/home/vsts/work/1/s/src/pages/dashboard/trips/completed.vue?macro=true";
import { default as indexs0gZVMbNRLMeta } from "/home/vsts/work/1/s/src/pages/dashboard/trips/index.vue?macro=true";
import { default as pendingrRBPUOJFe3Meta } from "/home/vsts/work/1/s/src/pages/dashboard/trips/pending.vue?macro=true";
import { default as tripspREwMDbpEzMeta } from "/home/vsts/work/1/s/src/pages/dashboard/trips.vue?macro=true";
import { default as experimentsbgbDXkfb0fMeta } from "/home/vsts/work/1/s/src/pages/experiments.vue?macro=true";
import { default as how_45it_45worksOgv2nVfJZHMeta } from "/home/vsts/work/1/s/src/pages/how-it-works.vue?macro=true";
import { default as logout9OrZjYS6rnMeta } from "/home/vsts/work/1/s/src/pages/index/logout.vue?macro=true";
import { default as signinhf1I2wlDhYMeta } from "/home/vsts/work/1/s/src/pages/index/signin.vue?macro=true";
import { default as signuphIWxYIJc4OMeta } from "/home/vsts/work/1/s/src/pages/index/signup.vue?macro=true";
import { default as indexsWhPMsLzMKMeta } from "/home/vsts/work/1/s/src/pages/index.vue?macro=true";
import { default as insurance_45and_45protectionQpTCEoEt1tMeta } from "/home/vsts/work/1/s/src/pages/insurance-and-protection.vue?macro=true";
import { default as ownerjdh5cLYB1RMeta } from "/home/vsts/work/1/s/src/pages/owner.vue?macro=true";
import { default as privacy_45policywZKB8CtPdaMeta } from "/home/vsts/work/1/s/src/pages/privacy-policy.vue?macro=true";
import { default as _91id_93nQ2B0M1D9UMeta } from "/home/vsts/work/1/s/src/pages/profile/[id].vue?macro=true";
import { default as _91code_93CGzJO2pENvMeta } from "/home/vsts/work/1/s/src/pages/recovery-password/[code].vue?macro=true";
import { default as indexOz8NSlRWkdMeta } from "/home/vsts/work/1/s/src/pages/recovery-password/index.vue?macro=true";
import { default as reviewsfc4lwfGQR1Meta } from "/home/vsts/work/1/s/src/pages/reviews.vue?macro=true";
import { default as rv_45deliveryHz9dIRYythMeta } from "/home/vsts/work/1/s/src/pages/rv-delivery.vue?macro=true";
import { default as index5Y5CYhoVPdMeta } from "/home/vsts/work/1/s/src/pages/rv-rental/index.vue?macro=true";
import { default as additionals7HJg5MIJEFMeta } from "/home/vsts/work/1/s/src/pages/rv-rental/request-to-book/[alias]/additionals.vue?macro=true";
import { default as index570VM3YKaDMeta } from "/home/vsts/work/1/s/src/pages/rv-rental/request-to-book/[alias]/index.vue?macro=true";
import { default as _91alias_93fKhn98jcRLMeta } from "/home/vsts/work/1/s/src/pages/rv-rental/request-to-book/[alias].vue?macro=true";
import { default as _91campgroundSlug_93tJg8Y8SQrmMeta } from "/home/vsts/work/1/s/src/pages/rv-rentals/[[keyword]]/campground/[campgroundSlug].vue?macro=true";
import { default as _91_46_46_46destinationPath_935NOv6lJsIlMeta } from "/home/vsts/work/1/s/src/pages/rv-rentals/[[keyword]]/destination/[...destinationPath].vue?macro=true";
import { default as indexZK5vRnjaahMeta } from "/home/vsts/work/1/s/src/pages/rv-rentals/[[keyword]]/index.vue?macro=true";
import { default as indexIrfESrG8reMeta } from "/home/vsts/work/1/s/src/pages/rv-rentals/index.vue?macro=true";
import { default as rv_45searchRcXj3qvmenMeta } from "/home/vsts/work/1/s/src/pages/rv-search.vue?macro=true";
import { default as service_45fees5t2p41AXDkMeta } from "/home/vsts/work/1/s/src/pages/service-fees.vue?macro=true";
import { default as share_45your_45triprNcWl7MVkyMeta } from "/home/vsts/work/1/s/src/pages/share-your-trip.vue?macro=true";
import { default as superhostcvOviZcKWZMeta } from "/home/vsts/work/1/s/src/pages/superhost.vue?macro=true";
import { default as terms_45serviceep2NzF21oGMeta } from "/home/vsts/work/1/s/src/pages/terms-service.vue?macro=true";
import { default as trust_45and_45safetyeuKVqZeD9FMeta } from "/home/vsts/work/1/s/src/pages/trust-and-safety.vue?macro=true";
export default [
  {
    name: "about___en___default",
    path: "/about",
    component: () => import("/home/vsts/work/1/s/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/home/vsts/work/1/s/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___fr",
    path: "/fr/a-propos",
    component: () => import("/home/vsts/work/1/s/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "affiliates___en___default",
    path: "/affiliates",
    component: () => import("/home/vsts/work/1/s/src/pages/affiliates/index.vue").then(m => m.default || m)
  },
  {
    name: "affiliates___en",
    path: "/en/affiliates",
    component: () => import("/home/vsts/work/1/s/src/pages/affiliates/index.vue").then(m => m.default || m)
  },
  {
    name: "affiliates___fr",
    path: "/fr/affilies",
    component: () => import("/home/vsts/work/1/s/src/pages/affiliates/index.vue").then(m => m.default || m)
  },
  {
    name: "app-download___en___default",
    path: "/app-download",
    component: () => import("/home/vsts/work/1/s/src/pages/app-download.vue").then(m => m.default || m)
  },
  {
    name: "app-download___en",
    path: "/en/app-download",
    component: () => import("/home/vsts/work/1/s/src/pages/app-download.vue").then(m => m.default || m)
  },
  {
    name: "app-download___fr",
    path: "/fr/telecharger-application",
    component: () => import("/home/vsts/work/1/s/src/pages/app-download.vue").then(m => m.default || m)
  },
  {
    name: "author-slug___en___default",
    path: "/author/:slug()",
    component: () => import("/home/vsts/work/1/s/src/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "author-slug___en",
    path: "/en/author/:slug()",
    component: () => import("/home/vsts/work/1/s/src/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "author-slug___fr",
    path: "/fr/auteur/:slug()",
    component: () => import("/home/vsts/work/1/s/src/pages/author/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___en___default",
    path: "/blog/:slug()",
    component: () => import("/home/vsts/work/1/s/src/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___en",
    path: "/en/blog/:slug()",
    component: () => import("/home/vsts/work/1/s/src/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___fr",
    path: "/fr/blogue/:slug()",
    component: () => import("/home/vsts/work/1/s/src/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog___en___default",
    path: "/blog",
    component: () => import("/home/vsts/work/1/s/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___en",
    path: "/en/blog",
    component: () => import("/home/vsts/work/1/s/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___fr",
    path: "/fr/blogue",
    component: () => import("/home/vsts/work/1/s/src/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "callback-oauth___en___default",
    path: "/callback-oauth",
    meta: callback_45oauth7DmN7JzlW7Meta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/callback-oauth.vue").then(m => m.default || m)
  },
  {
    name: "callback-oauth___en",
    path: "/en/callback-oauth",
    meta: callback_45oauth7DmN7JzlW7Meta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/callback-oauth.vue").then(m => m.default || m)
  },
  {
    name: "callback-oauth___fr",
    path: "/fr/callback-oauth",
    meta: callback_45oauth7DmN7JzlW7Meta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/callback-oauth.vue").then(m => m.default || m)
  },
  {
    name: "careers-data-analyst___en___default",
    path: "/careers/spotlight-data-analyst",
    component: () => import("/home/vsts/work/1/s/src/pages/careers-data-analyst.vue").then(m => m.default || m)
  },
  {
    name: "careers-data-analyst___en",
    path: "/en/careers/spotlight-data-analyst",
    component: () => import("/home/vsts/work/1/s/src/pages/careers-data-analyst.vue").then(m => m.default || m)
  },
  {
    name: "careers-data-analyst___fr",
    path: "/fr/careers/spotlight-data-analyst",
    component: () => import("/home/vsts/work/1/s/src/pages/careers-data-analyst.vue").then(m => m.default || m)
  },
  {
    name: "careers-owner-success___en___default",
    path: "/careers/spotlight-owner-success",
    component: () => import("/home/vsts/work/1/s/src/pages/careers-owner-success.vue").then(m => m.default || m)
  },
  {
    name: "careers-owner-success___en",
    path: "/en/careers/spotlight-owner-success",
    component: () => import("/home/vsts/work/1/s/src/pages/careers-owner-success.vue").then(m => m.default || m)
  },
  {
    name: "careers-owner-success___fr",
    path: "/fr/careers/spotlight-owner-success",
    component: () => import("/home/vsts/work/1/s/src/pages/careers-owner-success.vue").then(m => m.default || m)
  },
  {
    name: "careers-trust-and-safety___en___default",
    path: "/careers/spotlight-trust-and-safety",
    component: () => import("/home/vsts/work/1/s/src/pages/careers-trust-and-safety.vue").then(m => m.default || m)
  },
  {
    name: "careers-trust-and-safety___en",
    path: "/en/careers/spotlight-trust-and-safety",
    component: () => import("/home/vsts/work/1/s/src/pages/careers-trust-and-safety.vue").then(m => m.default || m)
  },
  {
    name: "careers-trust-and-safety___fr",
    path: "/fr/careers/spotlight-trust-and-safety",
    component: () => import("/home/vsts/work/1/s/src/pages/careers-trust-and-safety.vue").then(m => m.default || m)
  },
  {
    name: "careers___en___default",
    path: "/careers",
    component: () => import("/home/vsts/work/1/s/src/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: "careers___en",
    path: "/en/careers",
    component: () => import("/home/vsts/work/1/s/src/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: "careers___fr",
    path: "/fr/careers",
    component: () => import("/home/vsts/work/1/s/src/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: "category-slug___en___default",
    path: "/category/:slug()",
    component: () => import("/home/vsts/work/1/s/src/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "category-slug___en",
    path: "/en/category/:slug()",
    component: () => import("/home/vsts/work/1/s/src/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "category-slug___fr",
    path: "/fr/categorie/:slug()",
    component: () => import("/home/vsts/work/1/s/src/pages/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-agreement___en___default",
    path: "/dashboard/bookings/:id()/agreement",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/agreement.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-agreement___en",
    path: "/en/dashboard/bookings/:id()/agreement",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/agreement.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-agreement___fr",
    path: "/fr/dashboard/bookings/:id()/agreement",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/agreement.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-change-request___en___default",
    path: "/dashboard/bookings/:id()/change-request",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/change-request.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-bookings-id-change-request-accept___en___default",
    path: "accept",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/change-request/accept.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-change-request-decline___en___default",
    path: "decline",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/change-request/decline.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-change-request-review___en___default",
    path: "review",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/change-request/review.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-bookings-id-change-request___en",
    path: "/en/dashboard/bookings/:id()/change-request",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/change-request.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-bookings-id-change-request-accept___en",
    path: "accept",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/change-request/accept.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-change-request-decline___en",
    path: "decline",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/change-request/decline.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-change-request-review___en",
    path: "review",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/change-request/review.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-bookings-id-change-request___fr",
    path: "/fr/dashboard/bookings/:id()/change-request",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/change-request.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-bookings-id-change-request-accept___fr",
    path: "accept",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/change-request/accept.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-change-request-decline___fr",
    path: "decline",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/change-request/decline.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-change-request-review___fr",
    path: "review",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/change-request/review.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-bookings-id___en___default",
    path: "/dashboard/bookings/:id()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-bookings-id-index-accept___en___default",
    path: "accept",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/accept.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-accepted___en___default",
    path: "accepted",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/accepted.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-cancel___en___default",
    path: "cancel",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/cancel.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-complete___en___default",
    path: "complete",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/complete.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-custom-quote___en___default",
    path: "custom-quote",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/custom-quote.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-decline___en___default",
    path: "decline",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/decline.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-edit___en___default",
    path: "edit",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-income-protection___en___default",
    path: "income-protection",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/income-protection.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-insurance___en___default",
    path: "insurance",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/insurance.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-bookings-id___en",
    path: "/en/dashboard/bookings/:id()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-bookings-id-index-accept___en",
    path: "accept",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/accept.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-accepted___en",
    path: "accepted",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/accepted.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-cancel___en",
    path: "cancel",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/cancel.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-complete___en",
    path: "complete",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/complete.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-custom-quote___en",
    path: "custom-quote",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/custom-quote.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-decline___en",
    path: "decline",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/decline.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-edit___en",
    path: "edit",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-income-protection___en",
    path: "income-protection",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/income-protection.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-insurance___en",
    path: "insurance",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/insurance.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-bookings-id___fr",
    path: "/fr/dashboard/bookings/:id()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-bookings-id-index-accept___fr",
    path: "accept",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/accept.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-accepted___fr",
    path: "accepted",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/accepted.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-cancel___fr",
    path: "cancel",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/cancel.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-complete___fr",
    path: "complete",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/complete.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-custom-quote___fr",
    path: "custom-quote",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/custom-quote.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-decline___fr",
    path: "decline",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/decline.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-edit___fr",
    path: "edit",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/edit.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-income-protection___fr",
    path: "income-protection",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/income-protection.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-index-insurance___fr",
    path: "insurance",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/index/insurance.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-bookings-id-insurance-efnol___en___default",
    path: "/dashboard/bookings/:id()/insurance-efnol",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/insurance-efnol.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-insurance-efnol___en",
    path: "/en/dashboard/bookings/:id()/insurance-efnol",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/insurance-efnol.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-insurance-efnol___fr",
    path: "/fr/dashboard/bookings/:id()/insurance-efnol",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/insurance-efnol.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-invoice___en___default",
    path: "/dashboard/bookings/:id()/invoice",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/invoice.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-invoice___en",
    path: "/en/dashboard/bookings/:id()/invoice",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/invoice.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-invoice___fr",
    path: "/fr/dashboard/bookings/:id()/invoice",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/invoice.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-payment___en___default",
    path: "/dashboard/bookings/:id()/payment",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/payment.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-payment___en",
    path: "/en/dashboard/bookings/:id()/payment",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/payment.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-payment___fr",
    path: "/fr/dashboard/bookings/:id()/payment",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/payment.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-retry-payment___en___default",
    path: "/dashboard/bookings/:id()/retry-payment",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/retry-payment.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-retry-payment___en",
    path: "/en/dashboard/bookings/:id()/retry-payment",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/retry-payment.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-retry-payment___fr",
    path: "/fr/dashboard/bookings/:id()/retry-payment",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/retry-payment.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-review___en___default",
    path: "/dashboard/bookings/:id()/review",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/review.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-review___en",
    path: "/en/dashboard/bookings/:id()/review",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/review.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-review___fr",
    path: "/fr/dashboard/bookings/:id()/review",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/review.vue").then(m => m.default || m)
  },
  {
    name: send_45request_45moneyTcZgssIVloMeta?.name,
    path: "/dashboard/bookings/:id()/send-request-money",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-bookings-id-send-request-money-dispute___en___default",
    path: "dispute",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/dispute.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-send-request-money___en___default",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-send-request-money-request___en___default",
    path: "request",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/request.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-send-request-money-review___en___default",
    path: "review",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/review.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-send-request-money-send___en___default",
    path: "send",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/send.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-send-request-money-sent___en___default",
    path: "sent",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/sent.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-send-request-money-success___en___default",
    path: "success",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/success.vue").then(m => m.default || m)
  }
]
  },
  {
    name: send_45request_45moneyTcZgssIVloMeta?.name,
    path: "/en/dashboard/bookings/:id()/send-request-money",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-bookings-id-send-request-money-dispute___en",
    path: "dispute",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/dispute.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-send-request-money___en",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-send-request-money-request___en",
    path: "request",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/request.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-send-request-money-review___en",
    path: "review",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/review.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-send-request-money-send___en",
    path: "send",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/send.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-send-request-money-sent___en",
    path: "sent",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/sent.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-send-request-money-success___en",
    path: "success",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/success.vue").then(m => m.default || m)
  }
]
  },
  {
    name: send_45request_45moneyTcZgssIVloMeta?.name,
    path: "/fr/dashboard/bookings/:id()/send-request-money",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-bookings-id-send-request-money-dispute___fr",
    path: "dispute",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/dispute.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-send-request-money___fr",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-send-request-money-request___fr",
    path: "request",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/request.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-send-request-money-review___fr",
    path: "review",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/review.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-send-request-money-send___fr",
    path: "send",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/send.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-send-request-money-sent___fr",
    path: "sent",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/sent.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-send-request-money-success___fr",
    path: "success",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/send-request-money/success.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-bookings-id-services___en___default",
    path: "/dashboard/bookings/:id()/services",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/services.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-bookings-id-services-update-complete___en___default",
    path: "update/complete",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/services/update/complete.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-services-update___en___default",
    path: "update",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/services/update/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-services-update-review___en___default",
    path: "update/review",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/services/update/review.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-bookings-id-services___en",
    path: "/en/dashboard/bookings/:id()/services",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/services.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-bookings-id-services-update-complete___en",
    path: "update/complete",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/services/update/complete.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-services-update___en",
    path: "update",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/services/update/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-services-update-review___en",
    path: "update/review",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/services/update/review.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-bookings-id-services___fr",
    path: "/fr/dashboard/bookings/:id()/services",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/services.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-bookings-id-services-update-complete___fr",
    path: "update/complete",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/services/update/complete.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-services-update___fr",
    path: "update",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/services/update/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-services-update-review___fr",
    path: "update/review",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/services/update/review.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-bookings-id-update-payment___en___default",
    path: "/dashboard/bookings/:id()/update-payment",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/update-payment.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-update-payment___en",
    path: "/en/dashboard/bookings/:id()/update-payment",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/update-payment.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-id-update-payment___fr",
    path: "/fr/dashboard/bookings/:id()/update-payment",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/[id]/update-payment.vue").then(m => m.default || m)
  },
  {
    name: indexG3sLdXI9SgMeta?.name,
    path: "/dashboard/bookings",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/index.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-bookings-index-completed___en___default",
    path: "completed",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/index/completed.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-index-confirmed___en___default",
    path: "confirmed",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/index/confirmed.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-index-ezymatch___en___default",
    path: "ezymatch",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/index/ezymatch.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-index___en___default",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/index/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-index-pending___en___default",
    path: "pending",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/index/pending.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexG3sLdXI9SgMeta?.name,
    path: "/en/dashboard/bookings",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/index.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-bookings-index-completed___en",
    path: "completed",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/index/completed.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-index-confirmed___en",
    path: "confirmed",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/index/confirmed.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-index-ezymatch___en",
    path: "ezymatch",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/index/ezymatch.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-index___en",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/index/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-index-pending___en",
    path: "pending",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/index/pending.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexG3sLdXI9SgMeta?.name,
    path: "/fr/dashboard/bookings",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/index.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-bookings-index-completed___fr",
    path: "completed",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/index/completed.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-index-confirmed___fr",
    path: "confirmed",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/index/confirmed.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-index-ezymatch___fr",
    path: "ezymatch",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/index/ezymatch.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-index___fr",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/index/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bookings-index-pending___fr",
    path: "pending",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/bookings/index/pending.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-calendar___en___default",
    path: "/dashboard/calendar",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/calendar.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-calendar___en",
    path: "/en/dashboard/calendar",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/calendar.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-calendar___fr",
    path: "/fr/dashboard/calendar",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/calendar.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-favourites___en___default",
    path: "/dashboard/favourites",
    meta: favouritesqCXDR8Q0SyMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/favourites.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-favourites___en",
    path: "/en/dashboard/favourites",
    meta: favouritesqCXDR8Q0SyMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/favourites.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-favourites___fr",
    path: "/fr/dashboard/favourites",
    meta: favouritesqCXDR8Q0SyMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/favourites.vue").then(m => m.default || m)
  },
  {
    name: "dashboard___en___default",
    path: "/dashboard",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard___en",
    path: "/en/dashboard",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard___fr",
    path: "/fr/dashboard",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-insights___en___default",
    path: "/dashboard/insights",
    meta: insightsG0VLzXxFtEMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/insights.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-insights___en",
    path: "/en/dashboard/insights",
    meta: insightsG0VLzXxFtEMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/insights.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-insights___fr",
    path: "/fr/dashboard/insights",
    meta: insightsG0VLzXxFtEMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/insights.vue").then(m => m.default || m)
  },
  {
    name: messagesDdxmNbYtDRMeta?.name,
    path: "/dashboard/messages",
    meta: messagesDdxmNbYtDRMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/messages.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-messages-id___en___default",
    path: ":id()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-messages-archives___en___default",
    path: "archives",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/messages/archives.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-messages-enquiries___en___default",
    path: "enquiries",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/messages/enquiries.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-messages___en___default",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-messages-request___en___default",
    path: "request",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/messages/request.vue").then(m => m.default || m)
  }
]
  },
  {
    name: messagesDdxmNbYtDRMeta?.name,
    path: "/en/dashboard/messages",
    meta: messagesDdxmNbYtDRMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/messages.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-messages-id___en",
    path: ":id()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-messages-archives___en",
    path: "archives",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/messages/archives.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-messages-enquiries___en",
    path: "enquiries",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/messages/enquiries.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-messages___en",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-messages-request___en",
    path: "request",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/messages/request.vue").then(m => m.default || m)
  }
]
  },
  {
    name: messagesDdxmNbYtDRMeta?.name,
    path: "/fr/dashboard/messages",
    meta: messagesDdxmNbYtDRMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/messages.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-messages-id___fr",
    path: ":id()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-messages-archives___fr",
    path: "archives",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/messages/archives.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-messages-enquiries___fr",
    path: "enquiries",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/messages/enquiries.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-messages___fr",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-messages-request___fr",
    path: "request",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/messages/request.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profiled9T59OM8jUMeta?.name,
    path: "/dashboard/profile",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-empty___en___default",
    path: ":empty()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/[empty].vue").then(m => m.default || m)
  },
  {
    name: driversEf7sZAKNHHMeta?.name,
    path: "drivers",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/drivers.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-drivers___en___default",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/drivers/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-drivers-new___en___default",
    path: "new",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/drivers/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-profile-features___en___default",
    path: "features",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/features.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile___en___default",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-message-templates___en___default",
    path: "message-templates",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/message-templates.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-notifications___en___default",
    path: "notifications",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-payment___en___default",
    path: "payment",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/payment.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-payout___en___default",
    path: "payout",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/payout.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profiled9T59OM8jUMeta?.name,
    path: "/en/dashboard/profile",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-empty___en",
    path: ":empty()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/[empty].vue").then(m => m.default || m)
  },
  {
    name: driversEf7sZAKNHHMeta?.name,
    path: "drivers",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/drivers.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-drivers___en",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/drivers/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-drivers-new___en",
    path: "new",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/drivers/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-profile-features___en",
    path: "features",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/features.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile___en",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-message-templates___en",
    path: "message-templates",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/message-templates.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-notifications___en",
    path: "notifications",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-payment___en",
    path: "payment",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/payment.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-payout___en",
    path: "payout",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/payout.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profiled9T59OM8jUMeta?.name,
    path: "/fr/dashboard/profile",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-empty___fr",
    path: ":empty()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/[empty].vue").then(m => m.default || m)
  },
  {
    name: driversEf7sZAKNHHMeta?.name,
    path: "drivers",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/drivers.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-profile-drivers___fr",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/drivers/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-drivers-new___fr",
    path: "new",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/drivers/new.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-profile-features___fr",
    path: "features",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/features.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile___fr",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-message-templates___fr",
    path: "message-templates",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/message-templates.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-notifications___fr",
    path: "notifications",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-payment___fr",
    path: "payment",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/payment.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-profile-payout___fr",
    path: "payout",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/profile/payout.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91rv_45Id_938ZCQkiCwjrMeta?.name,
    path: "/dashboard/rvs/:rvID()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id].vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-rvs-rvId-addons___en___default",
    path: "addons",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/addons.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-calendar___en___default",
    path: "calendar",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/calendar.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-details___en___default",
    path: "details",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/details.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-earn-more___en___default",
    path: "earn-more",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/earn-more.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId___en___default",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-insurance___en___default",
    path: "insurance",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/insurance.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-photos___en___default",
    path: "photos",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/photos.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-preferences___en___default",
    path: "preferences",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/preferences.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-pricing___en___default",
    path: "pricing",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/pricing.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91rv_45Id_938ZCQkiCwjrMeta?.name,
    path: "/en/dashboard/rvs/:rvID()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id].vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-rvs-rvId-addons___en",
    path: "addons",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/addons.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-calendar___en",
    path: "calendar",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/calendar.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-details___en",
    path: "details",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/details.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-earn-more___en",
    path: "earn-more",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/earn-more.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId___en",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-insurance___en",
    path: "insurance",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/insurance.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-photos___en",
    path: "photos",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/photos.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-preferences___en",
    path: "preferences",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/preferences.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-pricing___en",
    path: "pricing",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/pricing.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91rv_45Id_938ZCQkiCwjrMeta?.name,
    path: "/fr/dashboard/rvs/:rvID()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id].vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-rvs-rvId-addons___fr",
    path: "addons",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/addons.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-calendar___fr",
    path: "calendar",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/calendar.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-details___fr",
    path: "details",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/details.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-earn-more___fr",
    path: "earn-more",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/earn-more.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId___fr",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-insurance___fr",
    path: "insurance",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/insurance.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-photos___fr",
    path: "photos",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/photos.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-preferences___fr",
    path: "preferences",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/preferences.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-rvId-pricing___fr",
    path: "pricing",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/[rv-Id]/pricing.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-rvs-deleted___en___default",
    path: "/dashboard/rvs/deleted",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/deleted.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-deleted___en",
    path: "/en/dashboard/rvs/deleted",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/deleted.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-deleted___fr",
    path: "/fr/dashboard/rvs/deleted",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/deleted.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-new___en___default",
    path: "/dashboard/rvs/new",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/new.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-rvs-new-step-field___en___default",
    path: ":step()/:field()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/new/[step]/[field].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-new-step___en___default",
    path: ":step()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/new/[step]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-rvs-new___en",
    path: "/en/dashboard/rvs/new",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/new.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-rvs-new-step-field___en",
    path: ":step()/:field()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/new/[step]/[field].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-new-step___en",
    path: ":step()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/new/[step]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-rvs-new___fr",
    path: "/fr/dashboard/rvs/new",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/new.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-rvs-new-step-field___fr",
    path: ":step()/:field()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/new/[step]/[field].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-new-step___fr",
    path: ":step()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/new/[step]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-rvs-published-id___en___default",
    path: "/dashboard/rvs/published/:id()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/published/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-published-id___en",
    path: "/en/dashboard/rvs/published/:id()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/published/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rvs-published-id___fr",
    path: "/fr/dashboard/rvs/published/:id()",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/rvs/published/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-survey-host___en___default",
    path: "/dashboard/survey/host",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/survey/host.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-survey-host___en",
    path: "/en/dashboard/survey/host",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/survey/host.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-survey-host___fr",
    path: "/fr/dashboard/survey/host",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/survey/host.vue").then(m => m.default || m)
  },
  {
    name: tripspREwMDbpEzMeta?.name,
    path: "/dashboard/trips",
    meta: tripspREwMDbpEzMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/trips.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-trips-completed___en___default",
    path: "completed",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/trips/completed.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-trips___en___default",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/trips/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-trips-pending___en___default",
    path: "pending",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/trips/pending.vue").then(m => m.default || m)
  }
]
  },
  {
    name: tripspREwMDbpEzMeta?.name,
    path: "/en/dashboard/trips",
    meta: tripspREwMDbpEzMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/trips.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-trips-completed___en",
    path: "completed",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/trips/completed.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-trips___en",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/trips/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-trips-pending___en",
    path: "pending",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/trips/pending.vue").then(m => m.default || m)
  }
]
  },
  {
    name: tripspREwMDbpEzMeta?.name,
    path: "/fr/dashboard/trips",
    meta: tripspREwMDbpEzMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/trips.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-trips-completed___fr",
    path: "completed",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/trips/completed.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-trips___fr",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/trips/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-trips-pending___fr",
    path: "pending",
    component: () => import("/home/vsts/work/1/s/src/pages/dashboard/trips/pending.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "experiments___en___default",
    path: "/experiments",
    meta: experimentsbgbDXkfb0fMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/experiments.vue").then(m => m.default || m)
  },
  {
    name: "experiments___en",
    path: "/en/experiments",
    meta: experimentsbgbDXkfb0fMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/experiments.vue").then(m => m.default || m)
  },
  {
    name: "experiments___fr",
    path: "/fr/experiments",
    meta: experimentsbgbDXkfb0fMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/experiments.vue").then(m => m.default || m)
  },
  {
    name: "how-it-works___en___default",
    path: "/how-it-works",
    component: () => import("/home/vsts/work/1/s/src/pages/how-it-works.vue").then(m => m.default || m)
  },
  {
    name: "how-it-works___en",
    path: "/en/how-it-works",
    component: () => import("/home/vsts/work/1/s/src/pages/how-it-works.vue").then(m => m.default || m)
  },
  {
    name: "how-it-works___fr",
    path: "/fr/comment-ca-fonctionne",
    component: () => import("/home/vsts/work/1/s/src/pages/how-it-works.vue").then(m => m.default || m)
  },
  {
    name: "index___en___default",
    path: "/",
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m),
    children: [
  {
    name: "index-logout___en___default",
    path: "/logout",
    component: () => import("/home/vsts/work/1/s/src/pages/index/logout.vue").then(m => m.default || m)
  },
  {
    name: "index-signin___en___default",
    path: "/signin",
    meta: signinhf1I2wlDhYMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/index/signin.vue").then(m => m.default || m)
  },
  {
    name: "index-signup___en___default",
    path: "/signup",
    meta: signuphIWxYIJc4OMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/index/signup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m),
    children: [
  {
    name: "index-logout___en",
    path: "logout",
    component: () => import("/home/vsts/work/1/s/src/pages/index/logout.vue").then(m => m.default || m)
  },
  {
    name: "index-signin___en",
    path: "signin",
    meta: signinhf1I2wlDhYMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/index/signin.vue").then(m => m.default || m)
  },
  {
    name: "index-signup___en",
    path: "signup",
    meta: signuphIWxYIJc4OMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/index/signup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/home/vsts/work/1/s/src/pages/index.vue").then(m => m.default || m),
    children: [
  {
    name: "index-logout___fr",
    path: "deconnexion",
    component: () => import("/home/vsts/work/1/s/src/pages/index/logout.vue").then(m => m.default || m)
  },
  {
    name: "index-signin___fr",
    path: "connexion",
    meta: signinhf1I2wlDhYMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/index/signin.vue").then(m => m.default || m)
  },
  {
    name: "index-signup___fr",
    path: "inscription",
    meta: signuphIWxYIJc4OMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/index/signup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "insurance-and-protection___en___default",
    path: "/insurance-and-protection",
    component: () => import("/home/vsts/work/1/s/src/pages/insurance-and-protection.vue").then(m => m.default || m)
  },
  {
    name: "insurance-and-protection___en",
    path: "/en/insurance-and-protection",
    component: () => import("/home/vsts/work/1/s/src/pages/insurance-and-protection.vue").then(m => m.default || m)
  },
  {
    name: "insurance-and-protection___fr",
    path: "/fr/assurances-et-protection",
    component: () => import("/home/vsts/work/1/s/src/pages/insurance-and-protection.vue").then(m => m.default || m)
  },
  {
    name: "owner___en___default",
    path: "/owner",
    meta: ownerjdh5cLYB1RMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/owner.vue").then(m => m.default || m)
  },
  {
    name: "owner___en",
    path: "/en/owner",
    meta: ownerjdh5cLYB1RMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/owner.vue").then(m => m.default || m)
  },
  {
    name: "owner___fr",
    path: "/fr/proprietaire",
    meta: ownerjdh5cLYB1RMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/owner.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en___default",
    path: "/privacy-policy",
    component: () => import("/home/vsts/work/1/s/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/home/vsts/work/1/s/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___fr",
    path: "/fr/politique-de-confidentialite",
    component: () => import("/home/vsts/work/1/s/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "profile-id___en___default",
    path: "/profile/:id()",
    meta: _91id_93nQ2B0M1D9UMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/profile/[id].vue").then(m => m.default || m)
  },
  {
    name: "profile-id___en",
    path: "/en/profile/:id()",
    meta: _91id_93nQ2B0M1D9UMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/profile/[id].vue").then(m => m.default || m)
  },
  {
    name: "profile-id___fr",
    path: "/fr/profil/:id()",
    meta: _91id_93nQ2B0M1D9UMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/profile/[id].vue").then(m => m.default || m)
  },
  {
    name: "recovery-password-code___en___default",
    path: "/recovery-password/:code()",
    meta: _91code_93CGzJO2pENvMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/recovery-password/[code].vue").then(m => m.default || m)
  },
  {
    name: "recovery-password-code___en",
    path: "/en/recovery-password/:code()",
    meta: _91code_93CGzJO2pENvMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/recovery-password/[code].vue").then(m => m.default || m)
  },
  {
    name: "recovery-password-code___fr",
    path: "/fr/recovery-password/:code()",
    meta: _91code_93CGzJO2pENvMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/recovery-password/[code].vue").then(m => m.default || m)
  },
  {
    name: "recovery-password___en___default",
    path: "/recovery-password",
    meta: indexOz8NSlRWkdMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/recovery-password/index.vue").then(m => m.default || m)
  },
  {
    name: "recovery-password___en",
    path: "/en/recovery-password",
    meta: indexOz8NSlRWkdMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/recovery-password/index.vue").then(m => m.default || m)
  },
  {
    name: "recovery-password___fr",
    path: "/fr/recovery-password",
    meta: indexOz8NSlRWkdMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/recovery-password/index.vue").then(m => m.default || m)
  },
  {
    name: "reviews___en___default",
    path: "/reviews",
    component: () => import("/home/vsts/work/1/s/src/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "reviews___en",
    path: "/en/reviews",
    component: () => import("/home/vsts/work/1/s/src/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "reviews___fr",
    path: "/fr/evaluations",
    component: () => import("/home/vsts/work/1/s/src/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: "rv-delivery___en___default",
    path: "/rv-delivery",
    component: () => import("/home/vsts/work/1/s/src/pages/rv-delivery.vue").then(m => m.default || m)
  },
  {
    name: "rv-delivery___en",
    path: "/en/rv-delivery",
    component: () => import("/home/vsts/work/1/s/src/pages/rv-delivery.vue").then(m => m.default || m)
  },
  {
    name: "rv-delivery___fr",
    path: "/fr/livraison-vr",
    component: () => import("/home/vsts/work/1/s/src/pages/rv-delivery.vue").then(m => m.default || m)
  },
  {
    name: "rv-rental___en___default",
    path: "/rv-rental/:alias()",
    meta: index5Y5CYhoVPdMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rental/index.vue").then(m => m.default || m)
  },
  {
    name: "rv-rental___en",
    path: "/en/rv-rental/:alias()",
    meta: index5Y5CYhoVPdMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rental/index.vue").then(m => m.default || m)
  },
  {
    name: "rv-rental___fr",
    path: "/fr/location-vr/:alias()",
    meta: index5Y5CYhoVPdMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rental/index.vue").then(m => m.default || m)
  },
  {
    name: _91alias_93fKhn98jcRLMeta?.name,
    path: "/rv-rental/request-to-book/:alias()",
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rental/request-to-book/[alias].vue").then(m => m.default || m),
    children: [
  {
    name: "rv-rental-request-to-book-alias-additionals___en___default",
    path: "additionals",
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rental/request-to-book/[alias]/additionals.vue").then(m => m.default || m)
  },
  {
    name: "rv-rental-request-to-book-alias___en___default",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rental/request-to-book/[alias]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91alias_93fKhn98jcRLMeta?.name,
    path: "/en/rv-rental/request-to-book/:alias()",
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rental/request-to-book/[alias].vue").then(m => m.default || m),
    children: [
  {
    name: "rv-rental-request-to-book-alias-additionals___en",
    path: "additionals",
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rental/request-to-book/[alias]/additionals.vue").then(m => m.default || m)
  },
  {
    name: "rv-rental-request-to-book-alias___en",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rental/request-to-book/[alias]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91alias_93fKhn98jcRLMeta?.name,
    path: "/fr/location-vr/request-to-book/:alias()",
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rental/request-to-book/[alias].vue").then(m => m.default || m),
    children: [
  {
    name: "rv-rental-request-to-book-alias-additionals___fr",
    path: "additionals",
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rental/request-to-book/[alias]/additionals.vue").then(m => m.default || m)
  },
  {
    name: "rv-rental-request-to-book-alias___fr",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rental/request-to-book/[alias]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "rv-rentals-keyword-campground-campgroundSlug___en___default",
    path: "/rv-rentals/:keyword?/campground/:campgroundSlug()",
    meta: _91campgroundSlug_93tJg8Y8SQrmMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rentals/[[keyword]]/campground/[campgroundSlug].vue").then(m => m.default || m)
  },
  {
    name: "rv-rentals-keyword-campground-campgroundSlug___en",
    path: "/en/rv-rentals/:keyword?/campground/:campgroundSlug()",
    meta: _91campgroundSlug_93tJg8Y8SQrmMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rentals/[[keyword]]/campground/[campgroundSlug].vue").then(m => m.default || m)
  },
  {
    name: "rv-rentals-keyword-campground-campgroundSlug___fr",
    path: "/fr/locations-vr/:keyword?/camping/:campgroundSlug()",
    meta: _91campgroundSlug_93tJg8Y8SQrmMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rentals/[[keyword]]/campground/[campgroundSlug].vue").then(m => m.default || m)
  },
  {
    name: "rv-rentals-keyword-destination-destinationPath___en___default",
    path: "/rv-rentals/:keyword?/destination/:destinationPath(.*)*",
    meta: _91_46_46_46destinationPath_935NOv6lJsIlMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rentals/[[keyword]]/destination/[...destinationPath].vue").then(m => m.default || m)
  },
  {
    name: "rv-rentals-keyword-destination-destinationPath___en",
    path: "/en/rv-rentals/:keyword?/destination/:destinationPath(.*)*",
    meta: _91_46_46_46destinationPath_935NOv6lJsIlMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rentals/[[keyword]]/destination/[...destinationPath].vue").then(m => m.default || m)
  },
  {
    name: "rv-rentals-keyword-destination-destinationPath___fr",
    path: "/fr/locations-vr/:keyword?/destination/:destinationPath(.*)*",
    meta: _91_46_46_46destinationPath_935NOv6lJsIlMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rentals/[[keyword]]/destination/[...destinationPath].vue").then(m => m.default || m)
  },
  {
    name: "rv-rentals-keyword___en___default",
    path: "/rv-rentals/:keyword()",
    meta: indexZK5vRnjaahMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rentals/[[keyword]]/index.vue").then(m => m.default || m)
  },
  {
    name: "rv-rentals-keyword___en",
    path: "/en/rv-rentals/:keyword()",
    meta: indexZK5vRnjaahMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rentals/[[keyword]]/index.vue").then(m => m.default || m)
  },
  {
    name: "rv-rentals-keyword___fr",
    path: "/fr/locations-vr/:keyword()",
    meta: indexZK5vRnjaahMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rentals/[[keyword]]/index.vue").then(m => m.default || m)
  },
  {
    name: "rv-rentals___en___default",
    path: "/rv-rentals",
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rentals/index.vue").then(m => m.default || m)
  },
  {
    name: "rv-rentals___en",
    path: "/en/rv-rentals",
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rentals/index.vue").then(m => m.default || m)
  },
  {
    name: "rv-rentals___fr",
    path: "/fr/locations-vr",
    component: () => import("/home/vsts/work/1/s/src/pages/rv-rentals/index.vue").then(m => m.default || m)
  },
  {
    name: "rv-search___en___default",
    path: "/rv-search",
    meta: rv_45searchRcXj3qvmenMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/rv-search.vue").then(m => m.default || m)
  },
  {
    name: "rv-search___en",
    path: "/en/rv-search",
    meta: rv_45searchRcXj3qvmenMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/rv-search.vue").then(m => m.default || m)
  },
  {
    name: "rv-search___fr",
    path: "/fr/recherche-vr",
    meta: rv_45searchRcXj3qvmenMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/rv-search.vue").then(m => m.default || m)
  },
  {
    name: "service-fees___en___default",
    path: "/service-fees",
    component: () => import("/home/vsts/work/1/s/src/pages/service-fees.vue").then(m => m.default || m)
  },
  {
    name: "service-fees___en",
    path: "/en/service-fees",
    component: () => import("/home/vsts/work/1/s/src/pages/service-fees.vue").then(m => m.default || m)
  },
  {
    name: "service-fees___fr",
    path: "/fr/frais-de-service",
    component: () => import("/home/vsts/work/1/s/src/pages/service-fees.vue").then(m => m.default || m)
  },
  {
    name: "share-your-trip___en___default",
    path: "/share-your-trip",
    component: () => import("/home/vsts/work/1/s/src/pages/share-your-trip.vue").then(m => m.default || m)
  },
  {
    name: "share-your-trip___en",
    path: "/en/share-your-trip",
    component: () => import("/home/vsts/work/1/s/src/pages/share-your-trip.vue").then(m => m.default || m)
  },
  {
    name: "share-your-trip___fr",
    path: "/fr/partagez-votre-voyage",
    component: () => import("/home/vsts/work/1/s/src/pages/share-your-trip.vue").then(m => m.default || m)
  },
  {
    name: "superhost___en___default",
    path: "/superhost",
    meta: superhostcvOviZcKWZMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/superhost.vue").then(m => m.default || m)
  },
  {
    name: "superhost___en",
    path: "/en/superhost",
    meta: superhostcvOviZcKWZMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/superhost.vue").then(m => m.default || m)
  },
  {
    name: "superhost___fr",
    path: "/fr/superhotes",
    meta: superhostcvOviZcKWZMeta || {},
    component: () => import("/home/vsts/work/1/s/src/pages/superhost.vue").then(m => m.default || m)
  },
  {
    name: "terms-service___en___default",
    path: "/terms-service",
    component: () => import("/home/vsts/work/1/s/src/pages/terms-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-service___en",
    path: "/en/terms-service",
    component: () => import("/home/vsts/work/1/s/src/pages/terms-service.vue").then(m => m.default || m)
  },
  {
    name: "terms-service___fr",
    path: "/fr/termes-utilisation",
    component: () => import("/home/vsts/work/1/s/src/pages/terms-service.vue").then(m => m.default || m)
  },
  {
    name: "trust-and-safety___en___default",
    path: "/trust-and-safety",
    component: () => import("/home/vsts/work/1/s/src/pages/trust-and-safety.vue").then(m => m.default || m)
  },
  {
    name: "trust-and-safety___en",
    path: "/en/trust-and-safety",
    component: () => import("/home/vsts/work/1/s/src/pages/trust-and-safety.vue").then(m => m.default || m)
  },
  {
    name: "trust-and-safety___fr",
    path: "/fr/confiance-et-securite",
    component: () => import("/home/vsts/work/1/s/src/pages/trust-and-safety.vue").then(m => m.default || m)
  }
]