/* eslint-disable @typescript-eslint/no-explicit-any */
import type { COOKIES } from '~/constants'
import type { CookieOptions } from '#app'

/**
 * Shim implementation of $cookies.
 * TODO: Should be converted to `useCookie` in the codebase.
 *
 * @deprecated
 */
export default defineNuxtPlugin(() => {
  const cookies = {
    get: (cookieName: COOKIES) => useCookie(cookieName).value,

    set: (cookieName: COOKIES, cookieValue: string, cookieOptions: CookieOptions<any> & {
      readonly?: false | undefined
    }) => {
      const cookie = useCookie(cookieName, cookieOptions)
      cookie.value = cookieValue

      return cookie
    },

    remove: (cookieName: COOKIES) => {
      const cookie = useCookie(cookieName)
      cookie.value = null
    },
  }

  return {
    provide: {
      cookies,
    },
  }
})
