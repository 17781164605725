import validate from "/home/vsts/work/1/s/node_modules/nuxt/dist/pages/runtime/validate.js";
import clarity_45identify_45global from "/home/vsts/work/1/s/src/middleware/clarity-Identify.global.ts";
import maintenance_45mode_45global from "/home/vsts/work/1/s/src/middleware/maintenanceMode.global.ts";
import manifest_45route_45rule from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  clarity_45identify_45global,
  maintenance_45mode_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authentication: () => import("/home/vsts/work/1/s/src/middleware/authentication.ts"),
  keyword: () => import("/home/vsts/work/1/s/src/middleware/keyword.ts"),
  "minimal-layout": () => import("/home/vsts/work/1/s/src/middleware/minimal-layout.ts"),
  auth: () => import("/home/vsts/work/1/s/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.js")
}