import type { GeolocationData } from '~/types'
import { COUNTRY_SHORTS } from '~/constants/'

export default function useGeolocation() {
  const geolocation = useGeolocationData()
  const runtimeConfig = useRuntimeConfig()
  const headers = useRequestHeaders()
  const event = useRequestEvent()

  if (import.meta.server) {
    // Get location from IP
    const xForwardedFor = headers['x-forwarded-for'] ? headers['x-forwarded-for'].split(', ') : []
    const xRealIp = headers['x-real-ip']
    const xLocalIp = event?.node.req.connection.remoteAddress || event?.node.req.socket.remoteAddress
    const ipAddress = (xForwardedFor.length && xForwardedFor[0]) || xRealIp || xLocalIp

    let geolocationData: GeolocationData = {
      ipAddress,
      city: headers['cf-ipcity'],
      countryCode: headers['cf-ipcountry'],
      continent: headers['cf-ipcontinent'],
      longitude: headers['cf-iplongitude'] ? Number(headers['cf-iplongitude']) : null,
      latitude: headers['cf-iplatitude'] ? Number(headers['cf-iplatitude']) : null,
      regionCode: headers['cf-region-code'],
      metroCode: headers['cf-metro-code'],
      postalCode: headers['cf-postal-code'],
      timezone: headers['cf-timezone'],
    }

    if (ipAddress === '127.0.0.1' || ipAddress === '::1') {
      geolocationData = runtimeConfig.LocalGeolocation
    }

    geolocation.value = geolocationData
  }

  const isInCa = computed(() => geolocation.value?.countryCode === COUNTRY_SHORTS.CA)
  const isInUs = computed(() => geolocation.value?.countryCode === COUNTRY_SHORTS.US)
  const isInCaOrUs = computed(() => isInCa.value || isInUs.value)

  return {
    geolocation,
    isInCa,
    isInUs,
    isInCaOrUs,
  }
}
