import { trackPageViewed } from '~/lib/tracking'

/**
 * This plugin hooks into the router and make sure that after each route change
 * we track the page view.
 *
 * This also fires on initial load.
 */
export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()
  const { afterEach } = useRouter()

  afterEach((to, from) => {
    trackPageViewed({ $config: runtimeConfig.public, to, from })
  })
})
