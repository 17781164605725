export default function () {
  const localePath = useLocalePath()
  const { isLoggedIn } = useAuthentication()

  const linkListRv = computed(() => localePath(isLoggedIn.value ? 'dashboard-rvs-new' : 'owner'))

  return {
    linkListRv,
  }
}
