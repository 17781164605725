<template>
  <div
    class="zgrid"
    :class="[noGuttersClass]"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  noGutters?: boolean
}>()

const noGuttersClass = computed(() => {
  return props.noGutters ? 'zgrid-no-gutters' : undefined
})
</script>
