/**
 * This plugin provides custom $fetch implementations. These implementations are
 * mainly used to make API calls with predefined configurations.
 */
export default defineNuxtPlugin({
  setup() {
    const runtimeConfig = useRuntimeConfig()
    const { $captureError } = useNuxtApp()

    // Create a new $fetch instance for the Ghost API.
    const ghostFetch = $fetch.create({
      baseURL: runtimeConfig.public.blog.ghostApiUrl,
      onRequest({ options }) {
        options.query = {
          ...options.query,
          key: runtimeConfig.public.blog.ghostApiKey,
        }
      },
      onRequestError({ error }) {
        $captureError(error)
      },
      onResponseError({ response }) {
        $captureError(response)
      },
    })

    return {
      provide: {
        ghostFetch,
      },
    }
  },
})
