import { captureError } from '~/lib/logger'
import { trackError } from '~/lib/tracking'

type ErrorContext = Record<string, unknown>

/**
 * Captures errors and sends the errors to services.
 *
 * In dev mode, errors are sent to the console.
 * In production mode, client-side errors are handled by the tracking service.
 *
 * Server-side errors are logged to the console and picked up by Datadog.
 */
export default defineNuxtPlugin((nuxtApp) => {
  const headers = useRequestHeaders()
  const referrer = import.meta.client ? document.referrer : headers['Referer']

  const $captureError = (error: unknown, context?: ErrorContext) => {
    // If the context contains no internal referrer, use the referrer from the
    // request headers.
    if (context && !context.referrer) {
      context.referrer = referrer
    }

    if (import.meta.dev) {
      console.error(error, context)
    }
    else {
      if (import.meta.client) {
        trackError(error)
      }
      captureError(error, context)
    }
  }

  // Capture Vue client-side errors.
  nuxtApp.hook('vue:error', (error, instance, info) => {
    $captureError(error, { instance, info })
  })

  // Capture Nuxt server-side errors.
  nuxtApp.hook('app:error', (error) => {
    $captureError(error)
  })

  return {
    provide: {
      captureError: $captureError,
    },
  }
})
