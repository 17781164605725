export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"},{"name":"theme-color","content":"#032E5A"},{"name":"mobile-web-app-capable","content":"yes"},{"name":"apple-mobile-web-app-status-bar-style","content":"black-translucent"},{"name":"msapplication-TileColor","content":"#032E5A"},{"property":"og:app_id","content":"1759228977634218"}],"link":[{"rel":"preconnect","href":"https://cdn-s.rvezy.com"},{"rel":"preconnect","href":"https://cdn-d.rvezy.com"},{"rel":"manifest","href":"/manifest.webmanifest"},{"rel":"icon","type":"image/svg+xml","href":"https://cdn-s.rvezy.com/favicons/favicon.svg"},{"rel":"apple-touch-icon","sizes":"192x192","href":"https://cdn-s.rvezy.com/favicons/apple-touch-icon.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"https://cdn-s.rvezy.com/favicons/apple-touch-icon-180.png"},{"rel":"mask-icon","href":"https://cdn-s.rvezy.com/favicons/safari-pinned-tab.svg"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"https://cdn-s.rvezy.com/favicons/favicon-16x16.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"https://cdn-s.rvezy.com/favicons/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"192x192","href":"https://cdn-s.rvezy.com/favicons/android-chrome-192x192.png"},{"rel":"icon","type":"image/png","sizes":"512x512","href":"https://cdn-s.rvezy.com/favicons/android-chrome-512x512.png"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"en-CA"},"titleTemplate":"%s | RVezy","title":"RVezy"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt3/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000