import { captureError } from '~/lib/logger'

/**
 * This composable manages a user's favourited RVs and collections.
 *
 * Question: why do we have separate endpoints for different methods?
 * I.e.: `me/update-collection` instead of `me/collection` with a PUT method.
 */
export default function useFavouriteRVs() {
  const { $rentals } = useNuxtApp()
  const { isLoggedIn, user } = useAuthentication()
  const favourites = useFavourites()
  const isLoading = ref(false)

  /**
   * Makes sure that the user is logged in and that we're currently not already
   * running another action.
   */
  function checkGuards() {
    if (!isLoggedIn.value || !user.value?.Id) return

    if (isLoading.value) return
  }

  /**
   * Fetches the user's favourites.
   */
  async function getFavourites() {
    checkGuards()

    try {
      isLoading.value = true

      const favs = await $rentals('/api/user-profiles/me/favourites')

      if (favs) {
        favourites.value = favs
      }
    }
    catch (error) {
      captureError(error)
    }
    finally {
      isLoading.value = false
    }
  }

  /**
   * Clears the user's favourites.
   */
  function clearFavourites() {
    favourites.value = []
  }

  /**
   * Adds an RV to a collection, or adds it to the default collection if no
   * collection ID is provided.
   */
  async function addRvToCollection(RvId: string, CollectionId?: number) {
    checkGuards()

    try {
      isLoading.value = true

      const collectionListId = CollectionId !== undefined ? CollectionId : favourites.value?.[0]?.Id

      await $rentals('/api/user-profiles/me/add-to-favourites-list', {
        method: 'POST',
        body: {
          RvId,
          CollectionId: collectionListId,
          ProfileId: user.value?.Id,
        },
      })

      await getFavourites()
    }
    catch (error) {
      captureError(error)
    }
    finally {
      isLoading.value = false
    }
  }

  /**
   * Removes an RV from a collection.
   */
  async function removeRvFromCollection(id: number) {
    checkGuards()

    try {
      isLoading.value = true

      await $rentals('/api/user-profiles/me/remove-from-collection', {
        method: 'DELETE',
        query: {
          Id: id,
        },
      })

      await getFavourites()
    }
    catch (error) {
      captureError(error)
    }
    finally {
      isLoading.value = false
    }
  }

  /**
   * Creates a new collection and optionally adds an RV to it.
   */
  async function addCollectionToFavourites(collectionName: string) {
    checkGuards()

    try {
      isLoading.value = true

      const result = await $rentals('/api/user-profiles/me/create-new-collection', {
        method: 'POST',
        body: {
          CollectionName: collectionName,
          ProfileId: user.value?.Id,
        },
      })

      await getFavourites()

      return result
    }
    catch (error) {
      captureError(error)
    }
    finally {
      isLoading.value = false
    }
  }

  /**
   * Updates a collection's name.
   */
  async function updateCollectionInFavourites({ collectionId, newCollectionName }: { collectionId: number, newCollectionName: string }) {
    checkGuards()

    try {
      isLoading.value = true

      await $rentals('/api/user-profiles/me/update-collection', {
        method: 'PUT',
        body: {
          Id: collectionId,
          CollectionName: newCollectionName,
          ProfileId: user.value?.Id,
        },
      })

      await getFavourites()
    }
    catch (error) {
      captureError(error)
    }
    finally {
      isLoading.value = false
    }
  }

  /**
   * Removes a collection.
   */
  async function removeCollectionFromFavourites(collectionId: number) {
    checkGuards()

    try {
      isLoading.value = true

      await $rentals('/api/user-profiles/me/remove-collection', {
        method: 'DELETE',
        query: {
          Id: collectionId,
        },
      })

      await getFavourites()
    }
    catch (error) {
      captureError(error)
    }
    finally {
      isLoading.value = false
    }
  }

  /**
   * Checks if an RV is favourited in any of the collections.
   */
  function checkIfRvIsFavourited(rvId: string) {
    return favourites.value?.some((collection) => collection.Items?.some((item) => item.RVId === rvId))
  }

  /**
   * Finds the collection ID that the RV is favourited in.
   */
  function findCollectionIdByRvId(rvId: string) {
    return favourites.value.flatMap((item) => item.Items).find((item) => item?.RVId === rvId)?.Id
  }

  return {
    favourites,
    isLoading,

    getFavourites,
    clearFavourites,

    addCollectionToFavourites,
    updateCollectionInFavourites,
    removeCollectionFromFavourites,

    addRvToCollection,
    removeRvFromCollection,

    checkIfRvIsFavourited,
    findCollectionIdByRvId,
  }
}
