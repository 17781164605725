export default function () {
  const { t } = useI18n()

  const nuxtApp = useNuxtApp()
  const { routeBaseName } = useBaseName()

  const maintenanceMode = computed(() => {
    return nuxtApp.$experiment.isEnabled('maintenanceMode') && !['experiments'].includes(routeBaseName.value)
  })

  onBeforeMount(() => {
    if (maintenanceMode.value) {
      useHead({
        title: t('pages.maintenance.metaTitle'),
      })
    }
  })

  return {
    maintenanceMode,
  }
}
