import type { CircleIconVariants } from '~/types/style-guide'

export default function useToast() {
  const toasts = useToasts()

  function addToast(toast: { message: string, variant: CircleIconVariants }, time = 3500) {
    const id = generateUuid()
    toasts.value.push({ ...toast, id })

    setTimeout(() => {
      toasts.value = toasts.value.filter((t) => t.id !== id)
    }, time)
  }

  return {
    toasts,
    addToast,
  }
}
