import type {
  AUTHENTICATION_INTENT,
  AUTHENTICATION_PROVIDERS,
  AUTHENTICATION_TYPE,
} from '~/constants/authentication'

import type {
  Nullable,
  GeolocationData,
  Experiments,
  RvDetails,
} from '~/types'

import type {
  UserPermissionsResponse,
  UserFavouritesResponse,
} from '~/types/rental-api-aliases'

import type {
  Toast,
} from '~/types/style-guide'

/**
 * A collection of SSR-safe states to be re-used throughout the app. This should
 * _not_ contain any local state (just use refs in components for that).
 */

// A user's favourites.
export const useFavourites = () => useState<UserFavouritesResponse>('favourites', () => [])

// A user's permissions.
export const usePermissions = () => useState<UserPermissionsResponse>('permissions', () => [])

// Wether or not the authentication form is visible.
export const useAuthenticationModal = () => useState<Nullable<AUTHENTICATION_TYPE>>('authenticationModal', () => null)

export const useAuthenticationProvider = () =>
  useState<AUTHENTICATION_PROVIDERS | undefined>('authenticationProvider', () => undefined)

export const useAuthenticationIntent = () =>
  useState<AUTHENTICATION_INTENT | undefined>('authenticationIntent', () => undefined)

// Wether or not the header search form is visible.
export const useShowHeaderSearchForm = () => useState('showHeaderSearchForm', () => false)

// Stores a user's geolocation data.
export const useGeolocationData = () => useState<Nullable<GeolocationData>>('geolocation', () => null)

// Stores the hydration state of the app.
export const useHydrationStatus = () => useState('isHydrated', () => false)

// Wether or not the expanded view is visible.
export const useExpandedView = () => useState('expandedView', () => false)

// Experiments.
export const useExperiments = () => useState<Experiments>('experiments', () => ({
  evaluated: {},
  overridden: [],
}))

// Ask owner question modal.
export const useShowOwnerQuestionModal = () => useState<boolean>('showOwnerQuestionModal', () => false)

// Currently selected RV.
export const useSelectedRvDetails = () => useState<Nullable<RvDetails>>('selectedRvDetails', () => null)

// Global toasts queue.
export const useToasts = () => useState<Toast[]>('toasts', () => [])
