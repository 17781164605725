/**
 * This composable tracks wether or not the app is hydrated. This is useful in
 * cases where there's client-only logic that could result in hydration errors
 * when the server's state differs.
 */
export const useHydrationState = () => {
  const hydrationStatus = useHydrationStatus()

  onNuxtReady(() => (hydrationStatus.value = true))

  return { hydrationStatus }
}
