import isBot from '~/lib/isBot.js'

/**
 * This composable calls the GIS Geocoder API and returns the result for an address.
 */
export default function useGeocode() {
  const { userAgent } = useDevice()
  const { $gis, $captureError } = useNuxtApp()

  const geocodeByAddress = async (searchAddress: string) => {
    if (!searchAddress || isBot(userAgent)) {
      return null
    }

    try {
      const data = await $gis('/Geocoding/Geocode/Raw', {
        query: {
          Address: searchAddress,
        },
      })

      return data
    }
    catch (error) {
      $captureError(error)
    }
  }

  const geocodeByPlaceId = async (placeId: string) => {
    if (!placeId || isBot(userAgent)) {
      return null
    }

    try {
      const data = await $gis('/Place/Details/Raw', {
        query: {
          PlaceId: placeId,
        },
      })

      return data
    }
    catch (error) {
      $captureError(error)
    }
  }

  return {
    geocodeByAddress,
    geocodeByPlaceId,
  }
}
