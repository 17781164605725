/**
 * This plugin hooks into the router and make sure that before each route change
 * we determine wether or not the user should be redirected to the Nuxt 2
 * instance instead.
 *
 * We used to do this in middleware, but for some reason the middleware doesn't
 * always fire when using the back button and subsequently navigating again.
 */
export default defineNuxtPlugin(() => {
  const { beforeEach } = useRouter()
  const { shouldRedirectRouteUrl, shouldRedirectToNuxt2App, nuxt2BaseUrl } = useRouteManager()

  beforeEach(async (to) => {
    if (shouldRedirectRouteUrl(to.path)) {
      await navigateTo(to.fullPath, { external: true })
      return false
    }

    if (shouldRedirectToNuxt2App(to.path)) {
      await navigateTo(`${nuxt2BaseUrl}${to.fullPath}`, { external: true })
      return false
    }
  })
})
